<template>
  <div class="how">
  	<Nav />
  	<Breadcrumbs :items="crumbs"/>
    <div class="how__main">
    	<h1 class="ml-3 mt-3">How to Order</h1>
			<hr>
			<div class="flex flex-wrap">
        <div class="flex__col1">
        	<h3>For information:</h3>
					<ul>
						<li><strong>Email:</strong> You may email your purchase order directly to into@m-caliber.com.</li>
						<!-- <li><strong>Facsimile:</strong> You may fax your purchase order directly to us at 866-336-8969. We will be in the office to accept your faxed order in person between the hours of 8:00 AM and 5:00 PM (PT), Monday through Friday.</li>
						<li><strong>Mail:</strong> You may mail your purchase order to us at the following address:<br />
							<address>
          			Medea Medical Products<br>
          			Attn: Gina Moretti<br>
          			5653 Stoneridge Drive, Suite 119 <br>
          			Pleasanton, CA 94588
          		</address>
						</li>
						<li><strong>Online:</strong> You may submit your purchase order through our online portal. Simply <router-link :to="{name: 'register'}">create your account</router-link> and hit the upload button.</li> -->
					</ul>
					<!-- <p class="mt-2">Please <router-link :to="{name: 'contact'}">contact us</router-link> if you have any questions.</p> -->
        </div>
        <div class="flex__col2">
        	<!-- <div class="card mb-3">
        		<h4>Why Create an Account?</h4>
        		<hr>
        		<p>Creating an account in our system makes ordering and reordering easier for you. You can upload purchase orders, track your orders, receive email updates about your order, and easily connect with your account representative.</p>
        		<router-link :to="{name: 'register'}" tag="button" class="mt-3 btn btn__primary">Get Started</router-link>
        	</div>
        	<div class="card mb-3">
        		<p>*All orders are subject to Medea Medical Products’ written acceptance.</p>
        	</div> -->
        </div>
      </div>

			
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'howToOrder',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
	    let step2 = { title: "How to Order", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	Footer,
  	Breadcrumbs
  }
}
</script>